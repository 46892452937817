import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {IChatNotification, IUserNotifications} from '@core/domain/messages/message-notifications';
import {NgStorage} from '@core/enums/ngStorage/ngStorage.enum';
import {WebSocketsNameSpace, WebSocketsNotifications} from '@core/enums/webSockets/webSockets';
import {Store} from '@ngrx/store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {addChatNotification, getUserChatsNotifications, getUserUnreadChatsNotifications} from '@ui/modules/shared/modal-message/chat-notificationState/chat-notifications.actions';
import {
  addNotification,
  getUserNotifications,
  getUserUnreadNotifications
} from '@ui/modules/shared/modal-notifications/notificationState/notifications.actions';
import {environment} from 'environments/environment';
import {NgxNotifierService} from 'ngx-notifier';
import {BehaviorSubject} from 'rxjs';
import {Manager, Socket} from 'socket.io-client';
import {StorageService} from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationWebSocketService extends StorageService {
  private socket!: Socket | null;
  private isConnected = false;
  private userToken: string = '';
  private token$ = new BehaviorSubject<string | null>(null);
  public api = environment.api;

  constructor(
    public _http: HttpClient,
    public override _storage: StorageMap,
    public _notify: NgxNotifierService,
    public _router: Router,
    protected ngRxStoreService: Store,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super(_storage);
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('load', () => {
        this.checkToken();
      });
    }
  }

  async connectToServer(token: string): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {

      if (!token) {
        this.setStorage(NgStorage.CONNECTED_TO_NOTIFICATION_SERVER, 'false');
        return;
      }

      const manager = new Manager(`${this.api}`, {
        extraHeaders: {
          Authorization: token
        }
      });

      this.socket = manager.socket(WebSocketsNameSpace.NOTIFICATIONS);

      this.socket.on(WebSocketsNotifications.USER_HAS_BEEN_CONNECTED, (response: {success: boolean}) => {
        if (response.success) {
          this.isConnected = true;
          this.getUserNotifications();
          this.getChatNotifications();
          this.setStorage(NgStorage.CONNECTED_TO_NOTIFICATION_SERVER, 'true');
        } else {
          this.setStorage(NgStorage.CONNECTED_TO_NOTIFICATION_SERVER, 'false');
        }
      });

      this.socket.on(WebSocketsNotifications.NEW_NOTIFICATION, (notification: IUserNotifications) => {
        if (!notification) {
          return;
        }
        this.ngRxStoreService.dispatch(addNotification({notification}));
        this.playNotificationSound();

      })

      this.socket.on(WebSocketsNotifications.NEW_CHAT_NOTIFICATION, (notification: IChatNotification) => {
        this.ngRxStoreService.dispatch(addChatNotification({notification}));
        this.playChatNotificationSound();
      })

      this.socket.on(WebSocketsNotifications.USER_DISCONNECTED, () => {

        this.isConnected = false;
        this.disconnect();
        this.setStorage(NgStorage.CONNECTED_TO_NOTIFICATION_SERVER, 'false');

      });
    }
  }

  async getUserNotifications() {
    this.ngRxStoreService.dispatch(getUserNotifications({filters: {read: true}, paginate: {page: 1, limit: 25}}));
    this.ngRxStoreService.dispatch(getUserUnreadNotifications({filters: {read: false}, paginate: {page: 1, limit: 25}}));
  }
  async getChatNotifications() {
    this.ngRxStoreService.dispatch(getUserChatsNotifications({filters: {read: true}, paginate: {page: 1, limit: 25}}));
    this.ngRxStoreService.dispatch(getUserUnreadChatsNotifications({filters: {read: false}, paginate: {page: 1, limit: 25}}));
  }

  async checkToken() {
    this.userToken = await this.getStorage(NgStorage.TOKEN) as string;

    if (!this.isConnected) {
      this.connectToServer(this.userToken);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.socket = null;
    this.isConnected = false;
  }

  retryConnection() {
    this.checkToken();
  }

  /**
   * Play the notification sound when a notification is received.
   * @private
   */
  private playNotificationSound() {
    const audio = new Audio('/assets/sound/notifications/user_notification.wav');
    audio.play();
  }
  /**
   * Play the chat notification sound when a chat notification is received.
   * @private
   */
  private playChatNotificationSound() {
    const audio = new Audio('/assets/sound/notifications/user_chat_notification.mp3');
    audio.play();
  }
}
