import {Error} from '@core/domain/api/api-error-response';
import {IChatNotification, INotificationResponse} from '@core/domain/messages/message-notifications';
import {Paginate} from '@core/domain/paginate/paginate';
import {Filters} from '@core/domain/products/product';
import {createAction, props} from '@ngrx/store';





export const getUserChatsNotifications = createAction('[chat-notifications] Get User Chats Notifications', props<{filters: Filters, paginate: Paginate}>())
export const getUserChatsNotificationsSuccess = createAction('[chat-notifications] Get User Chats Notifications Success', props<{chatNotifications: INotificationResponse}>());
export const getUserChatsNotificationsFailure = createAction('[chat-notifications] Get User Chats Notifications Failure', props<{error: Error}>());

export const getUserUnreadChatsNotifications = createAction('[chat-notifications] Get User Unread Chats Notifications', props<{filters: Filters, paginate: Paginate}>())
export const getUserUnreadChatsNotificationsSuccess = createAction('[chat-notifications] Get User Unread Chats Notifications Success', props<{chatUnreadNotifications: INotificationResponse}>());
export const getUserUnreadChatsNotificationsFailure = createAction('[chat-notifications] Get User Unread Chats Notifications Failure', props<{error: Error}>());

export const addChatNotification = createAction('[chat-notifications] Add Notification', props<{notification: IChatNotification}>());
export const addChatNotificationSuccess = createAction('[chat-notifications] Add Notification Success', props<{notification: IChatNotification}>());
export const addChatNotificationFailure = createAction('[chat-notifications] Add Notification Failure', props<{error: Error}>());

export const markChatNotificationAsRead = createAction('[chat-notifications] Mark Notification As Read', props<{notificationChatId: string}>());
export const markChatNotificationAsReadSuccess = createAction('[chat-notifications] Mark Notification As Read Success', props<{notification: IChatNotification}>());
export const markChatNotificationAsReadFailure = createAction('[chat-notifications] Mark Notification As Read Failure', props<{error: Error}>());
