import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RequestMethod} from '@core/enums/httpRequest/requestMethods.enum';
import {NgStorage} from '@core/enums/ngStorage/ngStorage.enum';
import {StorageMap} from '@ngx-pwa/local-storage';
import {environment} from 'environments/environment';
import {NgxNotifierService} from 'ngx-notifier';
import {catchError, from, lastValueFrom, map} from 'rxjs';
import {NotificationWebSocketService} from './notification/notification-ws.service';
import {StorageService} from './storage/storage.service';
interface ApiResponse<T> {
  data: T;
}
@Injectable({
  providedIn: 'root',
})
export class GlobalHttpService extends StorageService {
  public api = environment.api;

  constructor(public _http: HttpClient, public override _storage: StorageMap, public _notify: NgxNotifierService, public _router: Router, public notificationWebSocketService: NotificationWebSocketService) {
    super(_storage);
  }

  public async getAuthHeaders(): Promise<HttpHeaders> {
    const token = await this.getStorage(NgStorage.TOKEN);

    if (token) {
      return new HttpHeaders().set('Authorization', `Bearer ${token}`)

    }
    return new HttpHeaders();
  }

  public async makeRequest<T, P>(route: string, payload: P, method: string = RequestMethod.GET): Promise<T> {
    return lastValueFrom(
      from(this.makeHttpRequest<ApiResponse<T>>(route, payload, method)).pipe(
        map((res: ApiResponse<T>) => res.data),
        catchError((error: HttpErrorResponse) => {
          console.error('Error:', error);
          throw error;
        })
      )
    );
  }


  public async makeHttpRequest<T>(
    url: string,
    options: unknown = {},
    method: string = RequestMethod.GET,
  ): Promise<T> {
    const headers = await this.getAuthHeaders();
    const requestOptions: object = method === RequestMethod.GET ? {headers} : {body: options, headers};
    return lastValueFrom(
      this._http.request<T>(method, url, requestOptions).pipe(
        map(response => response as T)
      )
    );
  }
  public async makeMediaRequest<T>(
    url: string,
    files: File[],
    key: string,
    method: string = RequestMethod.POST,
  ): Promise<T> {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append(key, file);
    });

    const headers = await this.getAuthHeaders();

    const requestOptions = {
      headers,
      body: formData,
      reportProgress: true,
      observe: 'events' as const,
    };

    return lastValueFrom(
      this._http.request<T>(method, url, requestOptions).pipe(
        map(response => response as T)
      )
    )
  }

}
