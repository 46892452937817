import {Injectable} from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import {environment} from 'environments/environment';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(public _storage: StorageMap) { }

  async getStorage(key: string) {
    return await lastValueFrom(
      this._storage.get(`${environment.appName}-${key}`),
    );
  }

  async setStorage(key: string, value: object | string | number | boolean) {
    await lastValueFrom(
      this._storage.set(`${environment.appName}-${key}`, value),
    );
  }

  async deleteStorage(key: string) {
    await lastValueFrom(
      this._storage.delete(`${environment.appName}-${key}`),
    );
  }

  async hasStorage(key: string) {
    return await lastValueFrom(this._storage.has(`${environment.appName}-${key}`));
  }

  watchStorage(key: string) {
    return this._storage.watch(`${environment.appName}-${key}`);
  }

  async updateStorage<T>(key: string, updatedItem: T, updateById: (item: T, updatedItem: T) => boolean) {

    const currentData = await this.getStorage(key) as T[] || [];

    if (Array.isArray(currentData)) {
      const updatedArray = currentData.map(item => {

        if (updateById(item, updatedItem)) {
          return {...item, ...updatedItem};
        }
        return item;
      });


      const exists = currentData.some(item => updateById(item, updatedItem));
      if (!exists) {
        updatedArray.push(updatedItem);
      }


      await this.setStorage(key, updatedArray);
    } else {

      await this.setStorage(key, [updatedItem]);
    }
  }

}
