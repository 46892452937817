
import {Error} from '@core/domain/api/api-error-response';
import {IUserNotificationResponse, IUserNotifications} from '@core/domain/messages/message-notifications';
import {Paginate} from '@core/domain/paginate/paginate';
import {Filters} from '@core/domain/products/product';
import {createAction, props} from '@ngrx/store';


export const getUserNotifications = createAction('[notifications] Get User Notifications', props<{filters: Filters, paginate: Paginate}>());
export const getUserNotificationsSuccess = createAction('[notifications] Get User Notifications Success', props<{notifications: IUserNotificationResponse}>());
export const getUserNotificationsFailure = createAction('[notifications] Get User Notifications Failure', props<{error: Error}>());

export const getUserUnreadNotifications = createAction('[notifications] Get User Unread Notifications', props<{filters: Filters, paginate: Paginate}>())
export const getUserUnreadNotificationsSuccess = createAction('[notifications] Get User Unread Notifications Success', props<{notifications: IUserNotificationResponse}>());
export const getUserUnreadNotificationsFailure = createAction('[notifications] Get User Unread Notifications Failure', props<{error: Error}>());

export const addNotification = createAction('[notifications] Add Notification', props<{notification: IUserNotifications}>());
export const markNotificationAsRead = createAction('[notifications] Mark Notification As Read', props<{notificationId: string}>());
