export enum WebSocketsEnums {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  UPLOAD_PROGRESS = 'uploadProgress',
  JOIN_CHAT = 'joinChat',
  JOINED_CHAT = 'joinedChat',
  MARK_AS_READ = 'markMessagesAsRead',
  RESTORED_MESSAGES = 'restoreMessages',
  MESSAGE = 'message',
  MESSAGE_STATUS_UPDATED = 'messageStatusUpdated',
  SEND_MESSAGE = 'sendMessage',
  MESSAGE_READ = 'messageRead',
  REGISTER_PRODUCT = 'registerProduct'
}

export enum WebSocketsNameSpace {
  MEDIA_UPLOAD = '/media-upload',
  CONNECT_CHAT = '/comercialChat',
  NOTIFICATIONS = '/notifications',
}

export enum WebSocketsNotifications {
  TOKEN_VERIFICATION_ERROR = 'Error verifying token or finding user',
  TOKEN_VERIFIED = 'token verified',
  USER_NOT_FOUND = 'User not found',
  USER_DISCONNECTED = 'userDisconnected',
  USER_HAS_BEEN_CONNECTED = 'hasBeenConnected',
  TOKEN_NOT_PROVIDED = 'Token verified for user ID:',
  NEW_NOTIFICATION = 'newNotification',
  MESSAGE_READ = 'messageRead',
  EXISTING_NOTIFICATIONS = 'existingNotifications',
  REQUEST_NOTIFICATIONS = 'requestNotifications',
  EXISTING_CHAT_NOTIFICATIONS = 'existingChatNotifications',
  REQUEST_CHAT_NOTIFICATIONS = 'requestChatNotifications',
  NEW_CHAT_NOTIFICATION = 'newChatNotification',
  MARK_NOTIFICATION_AS_READ = 'markNotificationAsRead',
  MARK_MESSAGE_AS_READ = 'markMessageAsRead',
}
