export enum NgStorage {
  ALTERNATIVE_LOADER = 'alternative-loader',
  lOADER = 'loader',
  EDIT_IMAGES = 'product-images',
  EDIT_PRODUCT = 'product-info',
  IMAGES_TO_UPLOAD = 'product-stored-images',
  VIDEO_TO_UPLOAD = 'product-video',
  TOKEN = 'token',
  SHIPPING_DATA = 'shipping-data',
  USER = 'user',
  COUNTRY_USER = 'country-user',
  VIDEO_PREVIEW = 'videoPreviews',
  MAIN_CATEGORY = 'mainCategory',
  CATEGORY = 'category',
  DELIVERY_ADDRESS = 'delivery-address',
  CONFIRM = 'confirm',
  QUOTE = 'quote',
  STORE = 'store',
  CHAT_NOTIFICATIONS = 'chat-notifications',
  CHAT_UNREAD_NOTIFICATIONS = 'chat-unread-notifications',
  CHAT_NOTIFICATIONS_COUNT = 'chat-notifications-count',
  LAST_VIEWED_PRODUCTS = 'last-viewed-products',
  USER_NOTIFICATIONS = 'user-notifications',
  USER_UNREAD_NOTIFICATIONS = 'user-unread-notifications',
  USER_NOTIFICATIONS_COUNT = 'user-notifications-count',
  OTP_PHONE_OR_EMAIL = 'otpPhoneOrEmail',
  OTP_CODE = 'otpCode',
  CONNECTED_TO_NOTIFICATION_SERVER = 'connected-to-notification-server',
  FACE_PHOTO = 'face-photo',
  DOCUMENT_PHOTO = 'document-photo',
}

export enum ProductCreationFlowNgStorage {
  PRODUCT_STORE_CATEGORY = 'product-store-category',
  PRODUCT_STORE_IMAGES = 'product-new-images',
  CATEGORIES_AND_SUB = 'product-category-and-subcategory',
  CAT_FATHER_CHILDREN_GRANDSON = 'product-category-father-children-grandson',
  PRODUCT_INFO = 'product-new-info'
}
