import {ClassificationQueryOptions} from "@core/domain/classification/classification";
import {Paginate} from "@core/domain/paginate/paginate";
import {Filters, ProductPaginate} from "@core/domain/products/product";
import {WalletFilters} from "@core/domain/wallet/wallet";

export function toQueryString(filters: Filters | WalletFilters | ClassificationQueryOptions, paginate: Paginate | ProductPaginate): string {
  return new URLSearchParams({
    ...Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, String(value)])),
    ...paginate.limit !== undefined ? {limit: String(paginate.limit)} : {},
    ...paginate.page !== undefined ? {page: String(paginate.page)} : {}
  }).toString();
}


export function toQueryParamsString<T>(filters: T, paginate: Paginate): string {
  const params = new URLSearchParams();

  if (filters && typeof filters === 'object') {
    for (const key in filters) {
      const value = filters[key];
      if (value !== undefined) {
        params.append(key, String(value));
      }
    }
  }

  if (paginate.limit !== undefined) {
    params.append('limit', String(paginate.limit));
  }
  if (paginate.page !== undefined) {
    params.append('page', String(paginate.page));
  }

  return params.toString();
}

export function toQueryParamsStringWithoutPagination<T>(filters: T): string {
  const params = new URLSearchParams();
  if (filters && typeof filters === 'object') {
    for (const [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        params.append(key, String(value));
      }
    }
  }

  return params.toString();
}
