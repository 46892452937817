import {Injectable} from '@angular/core';
import {IChatNotification, INotificationResponse, IUserNotificationResponse} from '@core/domain/messages/message-notifications';
import {Paginate} from '@core/domain/paginate/paginate';
import {Filters} from '@core/domain/products/product';
import {RequestMethod} from '@core/enums/httpRequest/requestMethods.enum';
import {NOTIFICATION_ROUTES} from '@core/routes-api/notification/notification.routes';
import {toQueryString} from '@core/utils/filter-params-utils';
import {GlobalHttpService} from '../global-http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends GlobalHttpService {
  async getChatNotifications(queryParms: Filters, paginate?: Paginate): Promise<INotificationResponse> {
    const params = toQueryString(queryParms, paginate || {});
    return await this.makeRequest<INotificationResponse, unknown>(`${NOTIFICATION_ROUTES.getMessagesNotification}?${params}`, {}, RequestMethod.GET);
  }

  async getUserNotifications(queryParms: Filters, paginate?: Paginate): Promise<IUserNotificationResponse> {
    const params = toQueryString(queryParms || {}, paginate || {});
    return await this.makeRequest<IUserNotificationResponse, unknown>(`${NOTIFICATION_ROUTES.getUserNotification}?${params}`, {}, RequestMethod.GET);
  }

  async markUserNotificationAsRead(id: string): Promise<void> {
    return await this.makeRequest<void, unknown>(`${NOTIFICATION_ROUTES.markUserNotificationAsRead}/${id}`, {}, RequestMethod.PATCH);
  }

  /**
   * Marks a chat notification as read.
   * @deprecated 
   * @param id the id of the notification to mark as read
   * @returns a Promise that resolves when the notification has been marked as read
   */
  async markChatNotificationAsRead(id: string): Promise<void> {
    return await this.makeRequest<void, unknown>(`${NOTIFICATION_ROUTES.markChatNotificationAsRead}/${id}`, {}, RequestMethod.PATCH);
  }

  async markChatNotificationAsReadByChatId(id: string): Promise<IChatNotification> {
    return await this.makeRequest<IChatNotification, object>(`${NOTIFICATION_ROUTES.markChatNotificationAsReadByChatId}/${id}`, {}, RequestMethod.PATCH);
  }
}
